<script setup lang="ts">
import { onBeforeMount, ref } from "vue"
import router from "@/apps/recruiter/router"
import tokenService from "@/common/services/TokenService"
import userRepository, { type ILoginForm } from "@/apps/recruiter/repositories/UserRepository"
import { useStore } from "@/apps/recruiter/store"

const form = ref<ILoginForm>({})
const store = useStore()

onBeforeMount(async () => {
    if (tokenService.jwt.has()) {
        await router.replace({ name: "vacancies" })
    }
})

async function login() {
    form.value.loading = true
    form.value.invalidCredentials = false
    store.setUser({})

    try {
        const data = await userRepository.login({
            email: form.value.email,
            password: form.value.password
        })

        tokenService.setTokens(data)
        await router.replace({ name: "vacancies" })
    } catch (e) {
        form.value.invalidCredentials = true
        tokenService.removeTokens()
    } finally {
        form.value.loading = false
    }
}
</script>

<template>
    <q-layout view="hHh lpR fFf" class="bg-grey-3 column justify-center items-center">
        <q-page-container>
            <q-card class="login">
                <q-card-section>
                    <div
                        class="column items-center q-gutter-y-md"
                        style="width: 50%; margin-left: 45%; margin-top: 20%"
                    >
                        <div class="text-h6 text-white">Вход</div>
                        <q-input
                            dense
                            filled
                            class="bg-white full-width"
                            v-model="form.email"
                            clearable
                            type="email"
                            label="Email"
                            :error="form.invalidCredentials"
                            error-message="Неверный email или пароль"
                        />
                        <q-input
                            dense
                            filled
                            class="bg-white full-width"
                            v-model="form.password"
                            clearable
                            type="password"
                            label="Пароль"
                        />
                        <q-btn
                            color="primary"
                            label="Войти"
                            :loading="form.loading"
                            @click="login"
                            class="full-width"
                            :disable="!form.email || !form.password || form.loading"
                        />
                    </div>
                </q-card-section>
            </q-card>
        </q-page-container>
    </q-layout>
</template>

<style scoped>
.login {
    background-image: url("@/apps/recruiter/assets/kandinsky-download-1707743509991 2.png");
    background-size: cover;
    min-width: 600px;
    min-height: 400px;

    .q-field__bottom {
        padding: 4px;
        text-align: center;
    }
}
</style>
