<script setup lang="ts">
import tokenService from "@/common/services/TokenService"
import { useStore } from "@/apps/recruiter/store"
import { ref } from "vue"
import RequestPopup from "@/apps/recruiter/views/RequestPopup.vue"

const store = useStore()
const requestPopupOpen = ref<boolean>(false)

const logout = () => {
    tokenService.removeTokens()
    router.replace({ name: "login" })
}
</script>

<template>
    <q-layout view="hHh lpR fFf" class="bg-grey-3 column">
        <q-header bordered class="bg-primary">
            <q-toolbar>
                <q-toolbar-title style="font-size: 190%">
                    <div class="row items-center">
                        <div class="col" style="font-size: 150%">
                            <span class="text-bold text-blue-2">HARD</span>
                            <span class="text-blue-4">SKLR</span>
                        </div>
                        <div>
                            <q-btn icon="forum" flat @click="requestPopupOpen = true" />
                        </div>
                        <div class="text-subtitle1 q-mx-md">
                            {{ store.user?.email }}
                        </div>
                        <div>
                            <q-btn icon="logout" flat @click="logout" />
                        </div>
                    </div>
                </q-toolbar-title>
            </q-toolbar>
        </q-header>

        <q-page-container class="col-grow column">
            <RouterView />
        </q-page-container>
    </q-layout>
    <RequestPopup v-model="requestPopupOpen" />
</template>

<style scoped></style>
