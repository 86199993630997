import type { IUser } from "@/apps/recruiter/interfaces"
import client from "@/common/client"
import type { ILoginResponse } from "@/common/interfaces"

export interface ILoginForm {
    email?: string
    password?: string
    loading?: boolean
    invalidCredentials?: boolean
}

const userRepository = {
    get: async (): Promise<IUser> => {
        return await client.get("/api/recruiter/profile")
    },
    login: async (form: ILoginForm): Promise<ILoginResponse> => {
        return await client.post("/api/recruiter/login", form)
    }
}

export default userRepository
