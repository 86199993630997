import client from "@/common/client"
import type { IRequest } from "@/common/interfaces"

interface IUpdateStatus {
    id: number
    newStatus: string
}

const requestRepository = {
    getAll: async (): Promise<IRequest[]> => {
        return await client.get("/api/recruiter/requests")
    },
    addRequest: async (message: string): Promise<IRequest[]> => {
        return await client.post("/api/recruiter/requests", { message })
    },
    updateStatus: async (data: IUpdateStatus): Promise<IRequest[]> => {
        return await client.patch("/api/recruiter/requests", data)
    }
}

export default requestRepository
