export enum ROUTE {
    VACANCIES = "vacancies",
    VACANCY = "vacancy-edit",
    RESULT = "vacancy-result",
    CANDIDATES = "candidates",
    CANDIDATE = "candidate",
    INTERVIEW_REQUESTS = "interview-requests",
    INTERVIEW_REQUESTS_REPORTS = "interview-requests-reports",
    LOGIN = "login"
}
