import { createRouter, createWebHistory } from "vue-router"
import LayoutView from "@/apps/recruiter/views/LayoutView.vue"
import LoginView from "@/apps/recruiter/views/LoginView.vue"
import { useStore } from "@/apps/recruiter/store"
import guard from "@/common/router/guard"
import requireAuth from "@/common/router/requireAuth"
import userRepository from "@/apps/recruiter/repositories/UserRepository"
import variant from "@/common/utils/variant"
import { VARIANT } from "@/common/enum/variant"
import { ROUTE } from "@/apps/recruiter/enum/route"

const auth = requireAuth("login", userRepository.get)

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/recruiter",
            component: LayoutView,
            meta: {
                middleware: [auth]
            },
            children: [
                {
                    path: "vacancies",
                    name: ROUTE.VACANCIES,
                    component: () => import("@/apps/recruiter/views/vacancies/VacanciesView.vue")
                },
                {
                    path: "vacancies/:id",
                    name: ROUTE.VACANCY,
                    component: () => import("@/apps/recruiter/views/vacancy/VacancyEditView.vue")
                },
                {
                    path: "results/:id",
                    name: ROUTE.RESULT,
                    component: () =>
                        import("@/apps/recruiter/views/vacancy-result/VacancyResultView.vue")
                },
                {
                    path: "candidates",
                    name: ROUTE.CANDIDATES,
                    component: () => import("@/apps/recruiter/views/CandidatesView.vue")
                },
                {
                    path: "candidates/:id",
                    name: ROUTE.CANDIDATE,
                    component: () => import("@/apps/recruiter/views/candidate/CandidateView.vue")
                },
                variant.is(VARIANT.COPPER)
                    ? {
                          path: "interview-requests",
                          name: ROUTE.INTERVIEW_REQUESTS,
                          component: () =>
                              import(
                                  "@/apps/recruiter/views/interview-requests/InterviewRequestsView.vue"
                              )
                      }
                    : {},
                variant.is(VARIANT.COPPER)
                    ? {
                          path: "interview-requests-reports",
                          name: ROUTE.INTERVIEW_REQUESTS_REPORTS,
                          component: () =>
                              import(
                                  "@/apps/recruiter/views/interview-requests-reports/InterviewRequestsReportsView.vue"
                              )
                      }
                    : {}
            ]
        },
        {
            path: "/recruiter/login",
            name: ROUTE.LOGIN,
            component: LoginView
        }
    ]
})

router.beforeEach(guard(useStore))

export default router
