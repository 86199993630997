import { defineStore } from "pinia"
import type { IUser, IVacancy } from "@/apps/recruiter/interfaces"
import { ref } from "vue"
import vacancyRepository from "@/apps/recruiter/repositories/VacancyRepository"
import { convertReceivedVacancy } from "@/apps/recruiter/tools/utils"

export const useStore = defineStore("recruiter", () => {
    const user = ref<IUser>({})
    const vacancies = ref<IVacancy[]>([])
    const selectedVacancy = ref<IVacancy>({})
    const vacancySearch = ref<string>("")

    function setUser(data: IUser) {
        user.value = data
    }

    async function fetchVacancies() {
        const data = await vacancyRepository.getVacancies()
        vacancies.value = data.map(convertReceivedVacancy)
    }

    async function addNewVacancy(vacancy: IVacancy): number {
        const addedVacancy = await vacancyRepository.addVacancy(user.value?.id, vacancy)
        vacancies.value = [...vacancies.value, addedVacancy]
        return addedVacancy.id
    }

    function getVacancyById(id: number): IVacancy | undefined {
        return vacancies.value.find((vacancy: IVacancy) => vacancy.id === id)
    }

    function updateVacancy(receivedVacancy: IVacancy): number | undefined {
        for (const i in vacancies.value) {
            if (vacancies.value[i].id === receivedVacancy.id) {
                vacancies.value[i] = convertReceivedVacancy(receivedVacancy)
                return i
            }
        }

        return undefined
    }

    const setSelectedVacancy = (vacancy: IVacancy) => {
        selectedVacancy.value = vacancy
    }

    const setVacancySearch = (value: string) => {
        vacancySearch.value = value
    }

    return {
        user,
        vacancies,
        setUser,
        fetchVacancies,
        addNewVacancy,
        getVacancyById,
        updateVacancy,
        selectedVacancy,
        setSelectedVacancy,
        vacancySearch,
        setVacancySearch
    }
})
