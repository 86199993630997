import type { IVacancy } from "@/apps/recruiter/interfaces"
import client from "@/common/client"

interface IAddVacancyLanguage {
    vacancyId: number
    languageId: number
    gradeId: number
}

interface IAddVacancyTechnology {
    vacancyId: number
    technologyId: number
    gradeId: number
    include?: number[]
}

interface IAddVacancyExperience {
    vacancyId: number
    name: string
    include: number[]
}

interface IUpdateVacancyLanguages {
    languageIds: number[]
}

const vacancyRepository = {
    getVacancies: async (): Promise<IVacancy[]> => {
        return await client.get("/api/recruiter/vacancies")
    },
    getVacancy: async (id: number): Promise<IVacancy> => {
        return await client.get("/api/recruiter/vacancies/" + id)
    },
    addVacancy: async (variant: string): Promise<number> => {
        return await client.post("/api/recruiter/vacancies", { variant })
    },
    saveVacancy: async (vacancy: IVacancy): Promise<IVacancy> => {
        return await client.patch("/api/recruiter/vacancies", {
            id: vacancy.id,
            status: vacancy.status,
            title: vacancy.title,
            company: vacancy.company,
            questionnaireId: vacancy.questionnaire?.id
        })
    },
    addVacancyLanguage: async (data: IAddVacancyLanguage): Promise<IVacancy> => {
        return await client.post("/api/recruiter/vacancy_language", data)
    },
    addVacancyTechnology: async (data: IAddVacancyTechnology): Promise<IVacancy> => {
        return await client.post("/api/recruiter/vacancy_technology", data)
    },
    addVacancyExperience: async (data: IAddVacancyExperience): Promise<IVacancy> => {
        return await client.post("/api/recruiter/vacancy_experience", data)
    },
    updateVacancyLanguages: async (
        id: number,
        data: IUpdateVacancyLanguages
    ): Promise<IVacancy> => {
        return await client.patch("/api/recruiter/vacancies/" + id + "/update_languages", data)
    },
    deleteTechnology: async (vacancyId: number, technologyId: number): Promise<IVacancy> => {
        return await client.delete(
            "/api/recruiter/vacancies/" + vacancyId + "/technologies/" + technologyId
        )
    },
    deleteExperience: async (vacancyId: number, experienceId: number): Promise<IVacancy> => {
        return await client.delete(
            "/api/recruiter/vacancies/" + vacancyId + "/experiences/" + experienceId
        )
    }
}

export default vacancyRepository
