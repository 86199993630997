import type { IKnowledge, IVacancy } from "@/apps/recruiter/interfaces"
import {
    KNOWLEDGE_TYPE_EXPERIENCE,
    KNOWLEDGE_TYPE_LANGUAGE,
    KNOWLEDGE_TYPE_TECHNOLOGY
} from "@/apps/recruiter/constants"

export const convertReceivedVacancy = (receivedVacancy: IVacancy): IVacancy => ({
    ...receivedVacancy,
    languages: receivedVacancy.knowledges?.filter(
        (knowledge: IKnowledge) => knowledge.type === KNOWLEDGE_TYPE_LANGUAGE
    ),
    technologies: receivedVacancy.knowledges?.filter(
        (knowledge: IKnowledge) => knowledge.type === KNOWLEDGE_TYPE_TECHNOLOGY
    ),
    experiences: receivedVacancy.knowledges?.filter(
        (knowledge: IKnowledge) => knowledge.type === KNOWLEDGE_TYPE_EXPERIENCE
    )
})
