<script setup lang="ts">
import { onMounted, ref } from "vue"
import requestRepository from "@/apps/recruiter/repositories/RequestRepository"
import {
    REQUEST_STATUS_DONE,
    REQUEST_STATUS_MAP,
    REQUEST_STATUS_REFUSED,
    REQUEST_STATUS_VIEWED
} from "@/common/constants"
import type { IRequest } from "@/common/interfaces"

const open = defineModel<boolean>()
const rows = ref<IRequest[]>([])
const loading = ref<boolean>(false)
const newRequest = ref<string>("")
const addLoading = ref<boolean>(false)

const columns = [
    { name: "createdAt", label: "Создано", field: "createdAt", align: "center" },
    { name: "message", label: "Сообщение", field: "message", align: "center" },
    {
        name: "updatedAt",
        label: "Обновлено",
        field: (row: IRequest) => (row.createdAt !== row.updatedAt ? row.updatedAt : "-"),
        align: "center"
    },
    {
        name: "status",
        label: "Статус",
        field: (row: IRequest) => REQUEST_STATUS_MAP[row.status],
        align: "center"
    },
    {
        name: "action",
        field: (row: IRequest) =>
            [REQUEST_STATUS_DONE, REQUEST_STATUS_REFUSED].includes(row.status) ? row.id : undefined,
        align: "center"
    }
]

onMounted(async () => {
    await loadMessages()

    setInterval(loadMessages, 1000 * 60)
})

const loadMessages = async () => {
    try {
        loading.value = true
        rows.value = await requestRepository.getAll()
    } finally {
        loading.value = false
    }
}

const addRequest = async () => {
    try {
        addLoading.value = true
        rows.value = await requestRepository.addRequest(newRequest.value)
    } finally {
        addLoading.value = false
    }
}

const updateStatus = async (id: number) => {
    try {
        loading.value = true
        rows.value = await requestRepository.updateStatus({ id, newStatus: REQUEST_STATUS_VIEWED })
    } finally {
        loading.value = false
    }
}
</script>

<template>
    <q-dialog v-model="open">
        <q-card style="min-width: 800px">
            <q-card-section>
                <div class="text-h5 q-mb-md">Запросы администратору</div>
                <div class="row items-center q-mb-lg">
                    <div class="col q-pr-sm">
                        <q-input label="Новый запрос" outlined dense v-model="newRequest" />
                    </div>
                    <div>
                        <q-btn
                            label="Добавить"
                            color="primary"
                            :disable="!newRequest"
                            @click="addRequest"
                            :loading="addLoading"
                        />
                    </div>
                </div>
                <q-table
                    :columns="columns"
                    :loading="loading"
                    :rows="rows"
                    row-key="id"
                    :pagination="{ rowsPerPage: 10 }"
                >
                    <template v-slot:body-cell-action="props">
                        <q-td :props="props">
                            <q-btn
                                v-if="!!props.value"
                                icon="visibility"
                                flat
                                dense
                                color="primary"
                                @click="updateStatus(props.value)"
                            />
                        </q-td>
                    </template>
                </q-table>
            </q-card-section>
            <q-card-actions align="center">
                <q-btn label="Закрыть" color="primary" @click="open = false" />
                <q-btn icon="refresh" color="primary" @click="loadMessages" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<style scoped></style>
